import React, { useState, useEffect } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { db } from '../../firebase';
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    setDoc,
  } from 'firebase/firestore';
  import { useAuth } from '../../contexts/authContext';

  import ToDoListName from '../ui/todo-list-name';

  const style = {
    bg: `h-screen w-screen p-4 bg-gradient-to-r from-[#2F80ED] to-[#1CB5E0]`,
    container: `bg-slate-100 max-w-[500px] w-full m-auto rounded-md shadow-xl p-4`,
    heading: `text-3xl font-bold text-center text-gray-800 p-2`,
    form: `flex justify-between`,
    input: `border p-2 w-full text-xl`,
    button: `border p-4 ml-2 bg-purple-500 text-slate-100`,
    count: `text-center p-2`,
  };

const MyLists = () => {
    const [todos, setTodos] = useState([]);
    const { currentUser } = useAuth()
    const [input, setInput] = useState('');

    useEffect(() => {
        console.log(currentUser);
        const q = query(collection(db, `users/${currentUser.uid}/my-lists`));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        //console.log(querySnapshot)
        let todosArr = [];
        querySnapshot.forEach((doc) => {
            console.log(doc.data().name);
            //console.log(doc.id);
            todosArr.push({ ...doc.data(), id: doc.id });
        });
        setTodos(todosArr);

        // querySnapshot.forEach((doc) => {
        //     console.log(doc);
        //     todosArr.push({ ...doc.data(), id: doc.id });
        // });
        
        });
        return () => unsubscribe();
    }, []);

    const createList = async (e) => {
        e.preventDefault(e);
        if (input === '') {
        alert('Please enter a valid list name');
        return;
        }
        await addDoc(collection(db, `users/${currentUser.uid}/my-lists`), {
        name: input,
        itemCount: 0,
        }).then((res) => {
            setDoc(doc(db, 'todo-lists', res.id), {
                creator: currentUser.email
            }).then((response) => {
                console.log(response)
            });
        });
        setInput('');
    };

    return(
        <>
        <form onSubmit={createList} className={`flex justify-between`}>
            <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className={style.input}
                type='text'
                placeholder='Create new list'
            />
            <button className={style.button}>
                <AiOutlinePlus size={30} />
            </button>
        </form>
        
        <ul>
            {todos.map((todo, index) => (
                <ToDoListName 
                key={index}
                listName={todo.name} 
                itemCount={todo.itemCount}
                todoId={todo.id}/>
            ))}
            </ul>
        </>

    );

}

export default MyLists