import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from "@material-tailwind/react";
import { db } from '../../firebase';
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    setDoc,
  } from 'firebase/firestore';
  import { useAuth } from '../../contexts/authContext';

import {
    List,
    ListItem,
    ListItemSuffix,
    Chip,
    Card,
  } from "@material-tailwind/react";
   
  export function MyTodoList() {

    const [todos, setTodos] = useState([]);
    const { currentUser } = useAuth()
    const [newTodoList, setNewTodoList] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        console.log(currentUser);
        const q = query(collection(db, `users/${currentUser.uid}/my-lists`));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        //console.log(querySnapshot)
        let todosArr = [];
        querySnapshot.forEach((doc) => {
            console.log(doc.data().name);
            //console.log(doc.id);
            todosArr.push({ ...doc.data(), id: doc.id });
        });
        setTodos(todosArr);

        // querySnapshot.forEach((doc) => {
        //     console.log(doc);
        //     todosArr.push({ ...doc.data(), id: doc.id });
        // });
        
        });
        return () => unsubscribe();
    }, []);

    const onChange = ({ target }) => setNewTodoList(target.value);

    const createList = async (e) => {
        e.preventDefault(e);
        if (newTodoList === '') {
        alert('Please enter a valid list name');
        return;
        }
        await addDoc(collection(db, `users/${currentUser.uid}/my-lists`), {
        name: newTodoList,
        itemCount: 0,
        }).then((res) => {
            setDoc(doc(db, 'todo-lists', res.id), {
                creator: currentUser.email
            }).then((response) => {
                console.log(response)
            });
        });
        setNewTodoList('');
    };

    return (
        <>
        <Card className="w-full p-7 bg-gray-100">
        <span className='text-gray-900 mr-auto ml-auto text-xl mb-3'>My Todo Lists</span>
        <div className="relative flex w-full max-w-[100%] my-2 ml-auto mr-auto">
            <Input
                type="text"
                label="Create New Todo List"
                value={newTodoList}
                onChange={onChange}
                className="pr-20"
                containerProps={{
                className: "min-w-0",
                }}
            />
            <Button
                size="sm"
                color={newTodoList ? "gray" : "blue-gray"}
                disabled={!newTodoList}
                className="!absolute right-1 top-1 rounded"
                onClick={createList}
            >
                ADD
            </Button>
        </div>
      <Card className="w-full">
        <List>
        {todos.map((todo, index) => (
                // <ToDoListName 
                // key={index}
                // listName={todo.name} 
                // itemCount={todo.itemCount}
                // todoId={todo.id}/>
                <ListItem key={index} onClick={() => navigate(`/my-todo-list/${todo.id}/items`)}>
                    {todo.name}
                    <ListItemSuffix>
                    <Chip
                        value={todo.itemCount}
                        variant="ghost"
                        size="sm"
                        className="rounded-full"
                    />
                    </ListItemSuffix>
                </ListItem>
            ))}
        </List>
      </Card>
      </Card>
      </>
    );
  }