import React from 'react'; 
import { useNavigate } from 'react-router-dom';
import TestHeader, { NavbarSimple } from '../ui/TestHeader';

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";

const Dashboard = () => {

    const navigate = useNavigate();

    return(
        <>
            <NavbarSimple />
            <Card className="mt-10 w-full">
                <CardHeader color="blue-gray" className="relative h-56">
                    <img
                    src="https://images.unsplash.com/photo-1598791318878-10e76d178023?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
                    alt="card-image"
                    />
                </CardHeader>
                <CardBody>
                    <Typography variant="h5" color="blue-gray" className="mb-2">
                    My Todo Lists
                    </Typography>
                    <Typography>
                    Todo lists helps in organizing and setting up priorities, so that you can focus on what is important to you and helps you plan efficiently.
                    In this section you will find all the Todo lists which you own.
                    </Typography>
                </CardBody>
                <CardFooter className="pt-0">
                    <Button onClick={() => navigate(`/my-todo-list`)}>My Todo List</Button>
                </CardFooter>
            </Card>
            <Card className="mt-10 w-full">
                <CardHeader color="blue-gray" className="relative h-56">
                    <img
                    src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                    alt="card-image"
                    />
                </CardHeader>
                <CardBody>
                    <Typography variant="h5" color="blue-gray" className="mb-2">
                    Shared Lists
                    </Typography>
                    <Typography>
                    In this section you will find the Todo lists which are shared to you, 
                    so that you can help them in striking through the todo items in which ever possible way you can.
                    </Typography>
                </CardBody>
                <CardFooter className="pt-0">
                    <Button onClick={() => navigate(`/my-todo-list`)}>Todo's Shared With Me!</Button>
                </CardFooter>
            </Card>

            
        </>
    );

}

export default Dashboard;
