import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { db } from "../../firebase";
import { AiOutlinePlus } from "react-icons/ai";

import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  increment
} from "firebase/firestore";
import Todo from "../../Todo";
import TodoItem from "../ui/todo-item";

const style = {
  bg: `h-screen w-screen p-4 bg-gradient-to-r from-[#2F80ED] to-[#1CB5E0]`,
  container: `bg-slate-100 max-w-[500px] w-full m-auto rounded-md shadow-xl p-4`,
  heading: `text-3xl font-bold text-center text-gray-800 p-2`,
  form: `flex justify-between`,
  input: `border p-2 w-full text-xl`,
  button: `border p-4 ml-2 bg-purple-500 text-slate-100`,
  count: `text-center p-2`,
};

const ListItems = () => {
  const { currentUser } = useAuth();
  const { listId } = useParams();
  const [todos, setTodos] = useState([]);
  const [input, setInput] = useState("");
  const [listName, setListName] = useState("Loading...");

  useEffect(() => {
    console.log(currentUser);
    const listRef = doc(db, `users/${currentUser.uid}/my-lists/${listId}`);
    const listSnap = getDoc(listRef);
    listSnap.then(res => setListName(res.data().name))
    const q = query(collection(db, `todo-lists/${listId}/items`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let todosArr = [];

      querySnapshot.forEach((doc) => {
        console.log(doc);
        todosArr.push({ ...doc.data(), id: doc.id });
      });
      setTodos(todosArr);
    //   updateDoc(doc(db, `users/${currentUser.uid}/my-lists`, listId), {
    //     itemCount: todosArr.length,
    //   }).then((result) => {
    //     console.log(result);
    //   });
    });
    return () => unsubscribe();
  }, []);

  // Create todo
  const createTodo = async (e) => {
    e.preventDefault(e);
    if (input === "") {
      alert("Please enter a valid todo");
      return;
    }
    await addDoc(collection(db, `todo-lists/${listId}/items`), {
      itemName: input,
      completed: false,
      addedBy: currentUser.email,
    }).then((res) => {
      updateDoc(doc(db, `users/${currentUser.uid}/my-lists`, listId), {
        itemCount: increment(1)
      }).then((result) => {
        console.log(result);
      });
    });
    setInput("");
  };

  // Delete todo
  const deleteTodo = async (id) => {
    console.log(id);
    await deleteDoc(doc(db, `todo-lists/${listId}/items`, id))
    .then(() => {
        updateDoc(doc(db, `users/${currentUser.uid}/my-lists`, listId), {
            itemCount: increment(-1)
          }).then(res => console.log(res));
    })
  };

  // Update todo in firebase
  const toggleComplete = async (todo) => {
    await updateDoc(doc(db, `todo-lists/${listId}/items`, todo.id), {
      completed: !todo.completed,
    });
  };

  return (
    <>
      <div className={style.bg}>
        <div className={style.container}>
          <h3 className={style.heading}>{ listName }</h3>
          <form onSubmit={createTodo} className={style.form}>
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className={style.input}
              type="text"
              placeholder="Add Todo"
            />
            <button className={style.button}>
              <AiOutlinePlus size={30} />
            </button>
          </form>
          <ul>
            {todos.map((todo, index) => (
              <TodoItem
                key={index}
                todo={todo}
                toggleComplete={toggleComplete}
                deleteTodo={deleteTodo}
              />
            ))}
          </ul>
          {todos.length < 1 ? null : (
            <p className={style.count}>{`You have ${todos.length} todos`}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ListItems;
