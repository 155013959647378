import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Todo from '../../Todo';
import { db } from '../../firebase';
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { useAuth } from '../../contexts/authContext';

const Dojo = () => {

    useEffect(() => {
        
        const q = query(collection(db, 'users/user-1/my-lists'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        //console.log(querySnapshot)

        querySnapshot.forEach((doc) => {
            console.log(doc.data().name);
            //console.log(doc.id);
            
        });

        // querySnapshot.forEach((doc) => {
        //     console.log(doc);
        //     todosArr.push({ ...doc.data(), id: doc.id });
        // });
        
        });
        return () => unsubscribe();
    }, []);

    return (
        "Dojo..."
    );
}

export default Dojo