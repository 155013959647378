import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { db } from "../../firebase";
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    getDoc,
    addDoc,
    deleteDoc,
    increment
  } from "firebase/firestore";

  import {
    List,
    ListItem,
    ListItemSuffix,
    Card,
    IconButton,
    Input, 
    Button
  } from "@material-tailwind/react";

  function TrashIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5"
      >
        <path
          fillRule="evenodd"
          d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

export function MyTodoListItems() {

    const { currentUser } = useAuth();
    const { listId } = useParams();
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState("");
    const [listName, setListName] = useState("Loading...");
    const navigate = useNavigate();

    useEffect(() => {
        console.log(currentUser);
        const listRef = doc(db, `users/${currentUser.uid}/my-lists/${listId}`);
        const listSnap = getDoc(listRef);
        listSnap.then(res => setListName(res.data().name))
        const q = query(collection(db, `todo-lists/${listId}/items`));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let todosArr = [];
    
          querySnapshot.forEach((doc) => {
            console.log(doc);
            todosArr.push({ ...doc.data(), id: doc.id });
          });
          setTodos(todosArr);
        //   updateDoc(doc(db, `users/${currentUser.uid}/my-lists`, listId), {
        //     itemCount: todosArr.length,
        //   }).then((result) => {
        //     console.log(result);
        //   });
        });
        return () => unsubscribe();
        }, []);

        const onChange = ({ target }) => setNewTodo(target.value);

        const createTodo = async (e) => {
            e.preventDefault(e);
            if (newTodo === "") {
            alert("Please enter a valid todo");
            return;
            }
            await addDoc(collection(db, `todo-lists/${listId}/items`), {
            itemName: newTodo,
            completed: false,
            addedBy: currentUser.email,
            }).then((res) => {
            updateDoc(doc(db, `users/${currentUser.uid}/my-lists`, listId), {
                itemCount: increment(1)
            }).then((result) => {
                console.log(result);
            });
            });
            setNewTodo("");
        };

        // Delete todo
        const deleteTodo = async (id) => {
            console.log(id);
            await deleteDoc(doc(db, `todo-lists/${listId}/items`, id))
            .then(() => {
                updateDoc(doc(db, `users/${currentUser.uid}/my-lists`, listId), {
                    itemCount: increment(-1)
                }).then(res => console.log(res));
            })
        };

        // Update todo in firebase
        const toggleComplete = async (todo) => {
            await updateDoc(doc(db, `todo-lists/${listId}/items`, todo.id), {
            completed: !todo.completed,
            });
        };

        return (
            <>
                <Card className="w-full p-7 bg-gray-100">
                    <span className='text-gray-900 mr-auto ml-auto text-xl mb-3'>{listName}</span>
                    <div className="relative flex w-full max-w-[100%] my-2 ml-auto mr-auto">
                        <Input
                            type="text"
                            label="Add Todo Item"
                            value={newTodo}
                            onChange={onChange}
                            className="pr-20"
                            containerProps={{
                            className: "min-w-0",
                            }}
                        />
                        <Button
                            size="sm"
                            color={newTodo ? "gray" : "blue-gray"}
                            disabled={!newTodo}
                            className="!absolute right-1 top-1 rounded"
                            onClick={createTodo}
                        >
                            ADD
                        </Button>
                    </div>
                    { todos.length > 0 && 
                        <Card className="w-full">
                        <List>
                        {todos.map((todo, index) => (
                                // <ToDoListName 
                                // key={index}
                                // listName={todo.name} 
                                // itemCount={todo.itemCount}
                                // todoId={todo.id}/>
                                <ListItem ripple={false} key={index}>
                                    <span className={todo.completed ? 'line-through text-green-600' : ''} onClick={() => toggleComplete(todo)}>{todo.itemName}</span>
                                    <ListItemSuffix>
                                        <IconButton variant="text" color="blue-gray" onClick={() => deleteTodo(todo.id)}>
                                            <TrashIcon/>
                                        </IconButton>
                                    </ListItemSuffix>
                                </ListItem>
                            ))}
                        </List>
                    </Card>
                    }
                </Card>
            </>
        );
}