import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const style = {
    li: `flex justify-between bg-slate-200 p-4 my-2 capitalize`,
    liComplete: `flex justify-between bg-slate-400 p-4 my-2 capitalize`,
    row: `flex bg-slate-200`,
    text: `ml-2 cursor-pointer`,
    textComplete: `ml-2 cursor-pointer line-through`,
    button: `cursor-pointer flex items-center`,
  };

const ToDoListName = ({ listName, itemCount, todoId }) => {
    const navigate = useNavigate();
    console.log(todoId)
    
    return(
        <>
            <div className='flex'>
                <div className='bg-slate-100 m-2 capitalize' onClick={() => navigate(`/mylist/${todoId}/items`)}>
                    {listName}
                </div>
                <div className='bg-slate-200 m-2'>
                    {itemCount}
                </div>

            </div>
        </>
    );
}

export default ToDoListName