import React, { useState, useEffect } from 'react';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import Todo from './Todo';
import { db } from './firebase';
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';

import { AuthProvider } from './contexts/authContext';
import { useRoutes } from 'react-router-dom';
import Login from './components/auth/login';
import Register from './components/auth/register';
import Home from './components/home';
import Header from './components/header';
import Dojo from './components/dojo';
import MyLists from './components/mylists';
import ListItems from './components/list-items';
import Dashboard from './components/dashboard';
import { MyTodoList } from './components/ui/MyTodoList';
import { MyTodoListItems } from './components/ui/MyTodoListItems';

const style = {
  bg: `h-screen w-screen p-4 bg-gradient-to-r from-[#2F80ED] to-[#1CB5E0]`,
  container: `bg-slate-100 max-w-[500px] w-full m-auto rounded-md shadow-xl p-4`,
  heading: `text-3xl font-bold text-center text-gray-800 p-2`,
  form: `flex justify-between`,
  input: `border p-2 w-full text-xl`,
  button: `border p-4 ml-2 bg-purple-500 text-slate-100`,
  count: `text-center p-2`,
};

function App() {
  const routesArray = [
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/dojo",
      element: <Dojo />
    },
    {
      path: "/mylist",
      element: <MyLists />
    },
    {
      path: "/my-todo-list",
      element: <MyTodoList />
    },
    {
      path: "/mylist/:listId/items",
      element: <ListItems />
    },
    {
      path: "/my-todo-list/:listId/items",
      element: <MyTodoListItems />
    }
  ];
  let routesElement = useRoutes(routesArray);
  const location = useLocation();
  const { hash, pathname, search } = location;
  const loadHeader = pathname == "/login" || pathname == "/register";
  console.log(pathname);

  // const [todos, setTodos] = useState([]);
  // const [input, setInput] = useState('');

  // // Create todo
  // const createTodo = async (e) => {
  //   e.preventDefault(e);
  //   if (input === '') {
  //     alert('Please enter a valid todo');
  //     return;
  //   }
  //   await addDoc(collection(db, 'todos'), {
  //     text: input,
  //     completed: false,
  //   });
  //   setInput('');
  // };

  // // Read todo from firebase
  // useEffect(() => {
  //   const q = query(collection(db, 'todos'));
  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     let todosArr = [];

  //     querySnapshot.forEach((doc) => {
  //       console.log(doc);
  //       todosArr.push({ ...doc.data(), id: doc.id });
  //     });
  //     setTodos(todosArr);
  //   });
  //   return () => unsubscribe();
  // }, []);

  // // Update todo in firebase
  // const toggleComplete = async (todo) => {
  //   await updateDoc(doc(db, 'todos', todo.id), {
  //     completed: !todo.completed,
  //   });
  // };

  // // Delete todo
  // const deleteTodo = async (id) => {
  //   await deleteDoc(doc(db, 'todos', id));
  // };

  // return (
  //   <div className={style.bg}>
  //     <div className={style.container}>
  //       <h3 className={style.heading}>Todo</h3>
  //       <form onSubmit={createTodo} className={style.form}>
  //         <input
  //           value={input}
  //           onChange={(e) => setInput(e.target.value)}
  //           className={style.input}
  //           type='text'
  //           placeholder='Add Todo'
  //         />
  //         <button className={style.button}>
  //           <AiOutlinePlus size={30} />
  //         </button>
  //       </form>
  //       <ul>
  //         {todos.map((todo, index) => (
  //           <Todo
  //             key={index}
  //             todo={todo}
  //             toggleComplete={toggleComplete}
  //             deleteTodo={deleteTodo}
  //           />
  //         ))}
  //       </ul>
  //       {todos.length < 1 ? null : (
  //         <p className={style.count}>{`You have ${todos.length} todos`}</p>
  //       )}
  //     </div>
  //   </div>
  // );
  return (
    <AuthProvider>
      {
        loadHeader && <Header />
      }
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
